import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

import { Link, graphql } from "gatsby"

import { Helmet } from "react-helmet"

import "./Introduction.css";

function Introduction(props) {
    const data = props.data;
    return (
        <div>
            <div style={{ display: "grid" }}>
                {/* You can use a GatsbyImage component if the image is dynamic */}
                <GatsbyImage
                    style={{
                        gridArea: "1/1",
                        // You can set a maximum height for the image, if you wish.
                        maxHeight: 700,
                        minHeight: "70vh"
                    }}
                    // You can optionally force an aspect ratio for the generated image
                    // aspectRatio={3 / 1}
                    // This is a presentational image, so the alt should be an empty string
                    alt="altImg"
                    // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash

                    loading={"eager"}

                    image={data.introductionBackgroundImage.imageFile.childImageSharp.gatsbyImageData}

                    formats={["auto", "webp", "avif"]}
                />
                <div
                    style={{
                        // By using the same grid area for both, they are stacked on top of each other
                        gridArea: "1/1",
                        position: "relative",
                        // This centers the other elements inside the hero component
                        placeItems: "center",
                        display: "grid",
                    }}
                >
                    {/* Any content here will be centered in the component */}
                    <div className="w-full container px-4">
                        <div className="text-center md:text-left px-4 md:px-8 my-c-container">
                            {/* <p className="home-sub">{data.introductionSubtitle}</p> */}
                            <h1 className="home-h1">{data.introductionHeader}</h1>
                            <p className="home-main-p py-4 block">{data.introductionParagraph}</p>
                            <div className="w-full flex justify-center md:justify-start mt-8 md:mt-0">
                                <Link to={"/" + data.introductionButtonUrl + "/"} className="home-intro-bttn" style={{color: "#333"}}>{data.introductionButton}</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Introduction;

export const query = graphql`fragment IntroductionFragment on WPGraphQL_Page_Homepageacf {
  introductionHeader
  introductionSubtitle
  introductionParagraph
  introductionButton
  introductionButtonUrl
  introductionBackgroundImage {
    altText
    sourceUrl
    imageFile {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
}
`